<template>
  <div class="wrapper">
    <img loading="lazy" alt="icon" id="img-top" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/asesoramiento.jpg" />
    <div class="background first">
      <div class="container">
        <h2>{{ $t('emprendedores.entrepreneurs-counseling') }}</h2>
        <p>
          Damos asesoramiento experto para acelerar el diseño y puesta en marcha
          de iniciativas emprendedoras. El servicio se ajusta en función del
          grado de madurez, concreción y desarrollo del proyecto:
        </p>
        <div>
          <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/bullet.png" />
          <span>
            Amplio asesoramiento para la identificación de las claves del proyecto,
            definición del horizonte temporal y elaboración del plan de empresa.
          </span>
        </div>
        <div>
          <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/bullet.png" />
          <span>
            Asesoramiento específico por áreas funcionales marketing, tecnología,
            estrategia, comercial, financiera, jurídica, etc.
          </span>
        </div>
      </div>
    </div>
    <div class="second">
      <div class="container">
        <b-row id="rowas">
          <b-col cols="12" class="col-md-6 colas">
            <div>
              <h3>¿A QUIÉN VA DIRIGIDO?</h3>
              <div class="text">
                <font-awesome-icon :icon="['fas', 'user']" class="icon"/>
                <p>
                  Dirigimos este servicio a alumnos de grado y postgrado y antiguos
                  alumnos de con ideas de negocio innovadoras y que busquen un
                  impulso para lanzar sus proyectos al mercado.
                </p>
              </div>
            </div>
            <div>
              <h3>¿QUIÉN PRESTA EL SERVICIO?</h3>
              <div class="text">
                <font-awesome-icon :icon="['fas', 'sitemap']" class="icon"/>
                <p>
                    <ol>
                        <li>Solicitud de asesoramiento al club de Emprendedores.</li>
                        <li>Reunión de diagnóstico con el equipo técnico.</li>
                        <li>Identificación áreas de trabajo.</li>
                        <li>Planificación.</li>
                        <li>Inicio del asesoramiento.</li>
                    </ol>
                </p>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="col-md-6 colas">
            <div>
              <h3>5 PASOS PARA ACCEDER AL SERVICIO</h3>
              <div class="text">
                <font-awesome-icon :icon="['fas', 'certificate']" class="icon"/>
                <p>
                  El equipo técnico de AICAD está integrado por profesionales de distintas
                  disciplinas y con experiencia en consultoría empresarial y aceleración
                  de empresas.
                </p>
              </div>
            </div>
            <div>
              <h3>¿QUÉ COSTE TIENE EL SERVICIO?</h3>
              <div class="text">
                <font-awesome-icon :icon="['fas', 'euro-sign']" class="icon"/>
                <p>
                  El asesoramiento general no tendrá coste para los emprendedores, el club
                  del gestor asume la totalidad de los gastos derivados si el proyecto es
                  viable.
                  <br>
                  En el caso del asesoramiento especializado dependerá de su alcance y
                  duración
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <h3>SELECCIONA LA SEDE CON LA QUE TE GUSTARÍA CONTACTAR</h3>
          <TeamMembers />
        </b-row>
      </div>
      <div class="bg-contacts">
        <ContactsForm />
      </div>
      <b-row class="clearfix" style="height: 50px; background: #f3f3f3"></b-row>
    </div>
  </div>
</template>

<script>
import ContactsForm from '../../components/ContactsForm.vue';
import TeamMembers from '../../components/team/TeamMembers.vue';

export default {
  components: {
    ContactsForm,
    TeamMembers
  },
  metaInfo() {
    return {
      title: this.$t('emprendedores.entrepreneurs-counseling'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('emprendedores.entrepreneurs-counseling') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('emprendedores.entrepreneurs-counseling') + ' - Club de Ejecutivos' },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
#img-top {
  width: 100%;
}

.background.first {
  background: #f2f2f2;

  .container {
    text-align: left;
    padding: 50px 15px;
  }
}

.second {
    padding: 50px 15px 0;
    text-align: left;

    .container {
        padding: 0 15px;

        #rowas {
            margin: 0;
            text-align: left;
        }

        .colas {
            padding: 0;

            .text {
                display: flex;
                // flex-wrap: wrap;
                margin-bottom: 50px;

                p {
                    padding: 0 60px 20px 20px;
                }
            }

            h3 {
                margin-bottom: 30px;
            }

            .icon {
                color: #009090;
                font-size: 100px;
                width: 100px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
  .second {
    .container {
      .colas {
        .text {
          flex-wrap: wrap;

          .icon {
            margin: 10px auto;
          }
        }
      }
    }
  }
}
</style>